import axios from "axios";

export default {
  initialize(url) {
    axios.defaults.baseURL = url;
    axios.defaults.headers.common["Content-Type"] = "application/json";
  },
  async fetchShipTypes(language, imagepack) {
    let response = await axios.get(
      `/ship?lang=${language}&imagepack=${imagepack}`
    );
    return response.data;
  },
  async fetchDefenseTypes(language, imagepack) {
    let response = await axios.get(
      `/defense?lang=${language}&imagepack=${imagepack}`
    );
    return response.data;
  },
  async fetchResearchTypes(language, imagepack) {
    let response = await axios.get(
      `/research?lang=${language}&imagepack=${imagepack}`
    );
    return response.data;
  },
  async fetchSpecialistTypes(language, imagepack) {
    let response = await axios.get(
      `/specialist?lang=${language}&imagepack=${imagepack}`
    );
    return response.data;
  },
  async fetchResourceTypes(language, imagepack) {
    let response = await axios.get(
      `/resource-type?lang=${language}&imagepack=${imagepack}`
    );
    return response.data;
  }
};
