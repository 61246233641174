import Vue from "vue";

import client from "api-client";

const data = {
  namespaced: true,
  state: {
    shipTypes: {},
    defenseTypes: {},
    researchTypes: {},
    specialistTypes: {},
    resourceTypes: {},
    localization: {}
  },
  getters: {
    /**
     *
     * @param state
     * @returns {{key: String, name: String, description: String, image: String, capacity: Number, speed: Number, fuel: Number, attack: Number, armor: Number, shields: Number, regeneration: Number, pilots: Number, limit: Number, cost: [{type: String, value: Number}], actions: String[], flags: String[], capacityPeople: Number, fuelStart: Number, fuelLand: Number, timeStart: Number, timeLand: Number, special: Object, alliance: Object}}
     */
    shipTypes(state) {
      return state.shipTypes;
    },
    defenseTypes(state) {
      return state.defenseTypes;
    },
    researchTypes(state) {
      return state.researchTypes;
    },
    specialistTypes(state) {
      return state.specialistTypes;
    },
    resourceTypes(state) {
      return state.resourceTypes;
    },
    imagePack() {
      return "Rediscovery";
    },
    forbiddenShipTypeFlags() {
      return ["npc", "unused"];
    }
  },
  mutations: {
    reset(state) {
      state.shipTypes = {};
      state.defenseTypes = {};
      state.researchTypes = {};
      state.specialistTypes = {};
      state.resourceTypes = {};
    },
    setShipTypes(state, { shipTypes }) {
      shipTypes.forEach(shipType =>
        Vue.set(state.shipTypes, shipType.key, shipType)
      );
    },
    setDefenseTypes(state, { defenseTypes }) {
      defenseTypes.forEach(defenseType =>
        Vue.set(state.defenseTypes, defenseType.key, defenseType)
      );
    },
    setResearchTypes(state, { researchTypes }) {
      researchTypes.forEach(researchType =>
        Vue.set(state.researchTypes, researchType.key, researchType)
      );
    },
    setSpecialistTypes(state, { specialistTypes }) {
      specialistTypes.forEach(specialistType =>
        Vue.set(state.specialistTypes, specialistType.key, specialistType)
      );
    },
    setResourceTypes(state, { resourceTypes }) {
      resourceTypes.forEach(resourceType =>
        Vue.set(state.resourceTypes, resourceType.key, resourceType)
      );
    }
  },
  actions: {
    async fetchData({ commit, getters }) {
      const forbiddenShipTypeFlags = getters["forbiddenShipTypeFlags"];
      const languageCode = "de_CH";
      const imagepack = getters["imagePack"];
      let [
        shipTypes,
        defenseTypes,
        researchTypes,
        specialistTypes,
        resourceTypes
      ] = await Promise.all([
        client.fetchShipTypes(languageCode, imagepack),
        client.fetchDefenseTypes(languageCode, imagepack),
        client.fetchResearchTypes(languageCode, imagepack),
        client.fetchSpecialistTypes(languageCode, imagepack),
        client.fetchResourceTypes(languageCode, imagepack)
      ]);
      shipTypes = shipTypes.data || [];
      defenseTypes = defenseTypes.data || [];
      researchTypes = researchTypes.data || [];
      specialistTypes = specialistTypes.data || [];
      resourceTypes = resourceTypes.data || [];

      for (let typeId of Object.keys(shipTypes)) {
        let shipType = shipTypes[typeId];
        if (
          shipType.flags.filter(f => forbiddenShipTypeFlags.includes(f))
            .length > 0
        ) {
          delete shipTypes[typeId];
          continue;
        }
        shipTypes[typeId] = shipType;
      }

      commit("setShipTypes", { shipTypes });
      commit("setDefenseTypes", { defenseTypes });
      commit("setResearchTypes", { researchTypes });
      commit("setSpecialistTypes", { specialistTypes });
      commit("setResourceTypes", { resourceTypes });
    }
  }
};

export default data;
