<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("touchstart", this.onTouchStart);
  },
  destroyed() {
    document.removeEventListener("touchstart", this.onTouchStart);
  },
  mounted() {
    this.$el.classList.add("mouse");
    this.loadLocale();
  },
  methods: {
    loadLocale() {
      if (localStorage.locale) {
        this.$i18n.locale = localStorage.locale;
      }
    },
    onTouchStart() {
      this.$el.classList.remove("mouse");
      document.addEventListener("mousemove", this.onMouseMove);
    },
    onMouseMove() {
      document.removeEventListener("mousemove", this.onMouseMove);
      if (!this.$el.classList.contains("mouse")) {
        this.$el.classList.add("mouse");
      }
    }
  }
};
</script>

<style scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
