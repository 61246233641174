import Vue from "vue";
import Vuex from "vuex";

import client from "api-client";

import data from "./modules/data";

Vue.use(Vuex);

const KEY = "app-data";

function get(key) {
  let data = {};
  try {
    data = JSON.parse(localStorage.getItem(KEY)) || {};
  } catch (e) {
    // Ignore
  }

  return data[key];
}

export default new Vuex.Store({
  modules: {
    data
  },
  actions: {
    async initialize({ dispatch }) {
      client.initialize(process.env.VUE_APP_API_URL);

      await dispatch("data/fetchData");
    },
    store(_, { key, value }) {
      let data = {};
      try {
        data = JSON.parse(localStorage.getItem(KEY)) || {};
      } catch (e) {
        // Ignore
      }

      data[key] = value;
      localStorage.setItem(KEY, JSON.stringify(data));
    },
    get(_, { key }) {
      return get(key);
    },
    reset({ commit /*, dispatch*/ }) {
      // dispatch("store", { key: "userId", value: null });
      commit("data/reset");
      commit("combat/reset");
    }
  }
});
