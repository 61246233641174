import Vue from "vue";
import VueRouter from "vue-router";
import NotFoundComponent from "../views/404";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFoundComponent
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/ships/combat",
    name: "combat-ships",
    component: () => import("@/views/Main/ShipStats.vue"),
    props: { category: "combat" }
  },
  {
    path: "/ships/logistics",
    name: "logistics-ships",
    component: () => import("@/views/Main/ShipStats.vue"),
    props: { category: "logistics" }
  },
  {
    path: "/ships/gatherers",
    name: "gatherer-ships",
    component: () => import("@/views/Main/ShipStats.vue"),
    props: { category: "gatherers" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
