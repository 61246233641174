import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import UUID from "vue-uuid";

import "@/assets/styles/app.scss";

Vue.config.productionTip = false;
Vue.use(UUID);

store.dispatch("initialize").then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#app");
});
